import React, { useState, useEffect, useCallback, useRef } from "react";
import {
	AreaHighlight,
	Highlight,
	PdfHighlighter,
	PdfLoader,
	Popup,
} from "react-pdf-highlighter";
import type { IHighlight } from "react-pdf-highlighter";

import StateDisplay from "./StateDisplay";
import IssueWarning from "./IssueWarning";
import { Spinner } from "./Spinner";
import { Coordinates } from "../interfaces/ApiResponse";

import "../styles/DocumentContent.css";
import "react-pdf-highlighter/dist/style.css";

interface DocumentContentProps {
	selectedDocs: File[];
	issueSection: string;
	issueReason: string;
	highlightPattern: string;
	position: Coordinates | undefined;
}

const errorsHighlights = {
	errors: [] as IHighlight[],
};

const parseIdFromHash = () =>
	document.location.hash.slice("#highlight-".length);

const resetHash = () => {
	document.location.hash = "";
};

const HighlightPopup = ({
	comment,
}: {
	comment: { text: string; emoji: string };
}) =>
	comment.text ? (
		<div className="Highlight__popup">
			{comment.emoji} {comment.text}
		</div>
	) : null;

const DocumentContent: React.FC<DocumentContentProps> = ({
	selectedDocs,
	issueSection,
	issueReason,
	highlightPattern,
	position,
}) => {
	const [initialUrl, setInitialUrl] = useState<string | null>(null);
	const [highlights, setHighlights] = useState<Array<IHighlight>>([]);

	useEffect(() => {
		if (selectedDocs.length > 0) {
			const url = URL.createObjectURL(selectedDocs[0]);
			setInitialUrl(url);
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	}, [selectedDocs]);

	useEffect(() => {
		if (initialUrl) {
			setHighlights(
				errorsHighlights.errors ? [...errorsHighlights.errors] : [],
			);
		}
	}, [initialUrl]);

	useEffect(() => {
		if (position) {
			errorsHighlights.errors[0] = position as IHighlight;
			setHighlights(
				errorsHighlights.errors ? [...errorsHighlights.errors] : [],
			);
		}
	}, [position]);

	const scrollViewerTo = useRef((highlight: IHighlight) => {
		// Implement scrolling logic here
	});

	const scrollToHighlightFromHash = useCallback(() => {
		const highlight = getHighlightById(parseIdFromHash());
		if (highlight) {
			scrollViewerTo.current(highlight);
		}
	}, []);

	useEffect(() => {
		window.addEventListener("hashchange", scrollToHighlightFromHash, false);
		return () => {
			window.removeEventListener(
				"hashchange",
				scrollToHighlightFromHash,
				false,
			);
		};
	}, [scrollToHighlightFromHash]);

	const getHighlightById = (id: string) => {
		return highlights.find(highlight => highlight.id === id);
	};

	if (!initialUrl) {
		return null;
	}

	return (
		<div className="Highlighter" style={{ height: "100vh" }}>
			<StateDisplay />
			{highlightPattern && (
				<IssueWarning issueSection={issueSection} issueReason={issueReason} />
			)}
			{initialUrl && (
				<div
					style={{
						height: "100vh",
						width: "75vw",
						position: "relative",
					}}
					className="disable-cursor-activities"
				>
					<PdfLoader url={initialUrl} beforeLoad={<Spinner />}>
						{pdfDocument => (
							<PdfHighlighter
								pdfDocument={pdfDocument}
								enableAreaSelection={() => false}
								onScrollChange={resetHash}
								scrollRef={scrollTo => {
									scrollViewerTo.current = scrollTo;
									scrollToHighlightFromHash();
								}}
								onSelectionFinished={() => null}
								highlightTransform={(
									highlight,
									index,
									setTip,
									hideTip,
									viewportToScaled,
									screenshot,
									isScrolledTo,
								) => {
									const isTextHighlight = !highlight.content?.image;

									const component = isTextHighlight ? (
										<Highlight
											isScrolledTo={isScrolledTo}
											position={highlight.position}
											comment={highlight.comment}
										/>
									) : (
										<AreaHighlight
											isScrolledTo={isScrolledTo}
											highlight={highlight}
											onChange={() => {}}
										/>
									);

									return (
										<Popup
											popupContent={<HighlightPopup {...highlight} />}
											onMouseOver={() => {}}
											onMouseOut={() => {}}
											key={index}
										>
											{component}
										</Popup>
									);
								}}
								highlights={highlights}
							/>
						)}
					</PdfLoader>
				</div>
			)}
		</div>
	);
};

export default DocumentContent;
