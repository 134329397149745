import React, { useState, useEffect, useRef } from "react";
import { ApiResponse } from "../interfaces/ApiResponse";
import { transferEditedFile } from "../services/inferenceApi";
import { ReactComponent as Icon } from "../assets/DownloadIcon.svg";
import { ReactComponent as UploadIcon } from "../assets/UploadIcon.svg";
import { ErrorHandler } from "../errors/ErrorHandler";
import styles from "../styles/Sidebar.module.css";

interface UploadFileTabProps {
	selectedDocs: File[];
	apiResponse: ApiResponse | null;
	issueKey: string;
}

const UploadFileTab: React.FC<UploadFileTabProps> = ({
	selectedDocs,
	apiResponse,
	issueKey,
}) => {
	const [hasFile, setHasFile] = useState(false);
	const [fileError, setFileError] = useState<string | null>(null);
	const [uploadFile, setUploadFile] = useState<File | null>(null);
	const [isLoadingContent, setIsLoadingContent] = useState<boolean>(false);
	const [dashCount, setDashCount] = useState(0);
	const spanRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		const updateDashCount = () => {
			if (spanRef.current) {
				const spanWidth = spanRef.current.offsetWidth;
				const dashWidth = 6.5;
				const count = Math.floor(spanWidth / dashWidth);
				setDashCount(count);
			}
		};

		window.addEventListener("resize", updateDashCount);
		updateDashCount();

		return () => window.removeEventListener("resize", updateDashCount);
	}, [apiResponse]);

	const isValidFileType = (file: File) => {
		const fileType = file.name.split(".").pop()?.toLowerCase();
		return fileType === "pdf" || fileType === "docx";
	};

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files && files.length > 0) {
			const file = files[0];
			if (isValidFileType(file)) {
				setHasFile(true);
				setFileError(null);
				setUploadFile(file);
			} else {
				setHasFile(false);
				setFileError("Please upload a .pdf or .docx file.");
				e.target.value = "";
			}
		} else {
			setHasFile(false);
			setFileError("Please select a file.");
		}
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!issueKey) {
			setFileError("You don't have enough authority to upload file.");
			return;
		}

		if (!uploadFile || !apiResponse) return;

		setIsLoadingContent(true);
		try {
			const reader = new FileReader();
			reader.onload = async event => {
				if (event.target && event.target.result) {
					const base64Data = event.target.result.toString().split(",")[1];
					const result = await transferEditedFile(
						issueKey,
						base64Data,
						selectedDocs[0].name,
					);
					console.log("File transferred successfully:", result);
				}
			};
			reader.readAsDataURL(uploadFile);
		} catch (error) {
			console.error("Error transferring file:", error);
			const errorMessage = ErrorHandler.handleError(error);
			setFileError(errorMessage);
		} finally {
			setIsLoadingContent(false);
		}
	};

	if (apiResponse) {
		return (
			<div className={styles.updatedDocument}>
				<form onSubmit={handleSubmit}>
					<h2 className="text-black text-lg font-bold mb-2">Updated File</h2>
					<div className="flex flex-col bg-white rounded-lg shadow text-black font-semibold p-3">
						<div className="flex items-center">
							<input
								className="w-full"
								type="file"
								accept=".pdf, .docx"
								required
								onChange={onFileChange}
							/>
							{hasFile ? (
								<button type="button">
									<Icon />
								</button>
							) : (
								<button type="button" style={{ cursor: "default" }}>
									<UploadIcon />
								</button>
							)}
						</div>
					</div>
					<span ref={spanRef} className="text-gray-300 block w-full">
						{"-".repeat(dashCount)}
					</span>
					<div className="flex gap-x-3">
						<button
							className={styles.updateFileButton}
							type="submit"
							disabled={isLoadingContent}
						>
							{isLoadingContent ? "Processing..." : "Upload file to FCMS"}
						</button>
						{/* <button className={styles.updateFileButton} type="submit">
							{"Request review"}
						</button> */}
					</div>
					{fileError && <p className="text-red-500 mt-2">{fileError}</p>}
				</form>
			</div>
		);
	}

	return null;
};

export default UploadFileTab;
