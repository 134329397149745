import React from "react";
import styles from "../styles/Sidebar.module.css";

interface Option {
	value: string | boolean;
	label: string;
}

interface SelectInputProps {
	label: string;
	id: string;
	value: string | boolean;
	options: Option[];
	onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectInput: React.FC<SelectInputProps> = ({
	label,
	id,
	value,
	options,
	onChange,
}) => {
	return (
		<div>
			<h2 className="text-black mb-4">
				{label} (Required) <span className="text-red-500">*</span>
			</h2>
			<div className={styles.dropdown}>
				<select
					className="w-full"
					id={id}
					value={value.toString()}
					onChange={onChange}
					required
				>
					{options.map((option, index) => (
						<option key={index} value={option.value.toString()}>
							{option.label}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default SelectInput;
