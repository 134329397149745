import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import MainContainer from "./components/fcms/MainContainer";
import MainContainer2 from "./components/view/MainContainer2";

function App() {
	return (
		<Router>
			<div className="min-h-screen bg-gray-100">
				<Header />
				<main className="py-1">
					<Routes>
						<Route path="/" element={<MainContainer2 />} />
						<Route path="/view" element={<MainContainer />} />
					</Routes>
				</main>
			</div>
		</Router>
	);
}

export default App;
