import React, { useState, useEffect, useRef } from "react";
import styles from "../styles/Sidebar.module.css";

interface CheckTabProps {
	isLoadingContent: boolean;
	checkContentError: string | null;
}

const CheckTab: React.FC<CheckTabProps> = ({
	isLoadingContent,
	checkContentError,
}) => {
	const [dashCount, setDashCount] = useState(0);
	const spanRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		const updateDashCount = () => {
			if (spanRef.current) {
				const spanWidth = spanRef.current.offsetWidth;
				const dashWidth = 6.5;
				const count = Math.floor(spanWidth / dashWidth);
				setDashCount(count);
			}
		};

		window.addEventListener("resize", updateDashCount);
		updateDashCount();

		return () => window.removeEventListener("resize", updateDashCount);
	}, []);

	return (
		<div>
			<span ref={spanRef} className="text-gray-300 block w-full">
				{"-".repeat(dashCount)}
			</span>
			<div className="flex">
				<button
					className={styles.checkContentButton}
					type="submit"
					disabled={isLoadingContent}
				>
					{isLoadingContent ? "Processing..." : "Check content"}
				</button>
			</div>
			{checkContentError && (
				<p className="text-red-500 mt-2">{checkContentError}</p>
			)}
		</div>
	);
};

export default CheckTab;
