import React from "react";
import { ReactComponent as Icon } from "../assets/WarningIcon.svg";

interface IssueWarningProps {
	issueSection: string;
	issueReason: string;
}

const IssueWarning: React.FC<IssueWarningProps> = ({
	issueSection,
	issueReason,
}) => {
	if (issueSection != "") {
		return (
			<header>
				<div className="flex bg-white rounded-lg shadow mb-3 p-3 items-center">
					<div style={{ flexBasis: "64px", flexShrink: 0 }}>
						<Icon style={{ width: "64px", height: "64px" }} />
					</div>
					<div className="w-full ml-3">
						<div className="flex justify-between items-center">
							<h2 className="text-black text-xl font-bold">{issueSection}</h2>
						</div>
						<h3 className="text-gray-600">{issueReason}</h3>
					</div>
				</div>
			</header>
		);
	}

	return null;
};

export default IssueWarning;
