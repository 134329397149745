import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import DocumentContent from "../DocumentContent";
import { Coordinates } from "../../interfaces/ApiResponse";
import {
	fetchDocumentByToken,
	convertToPdf,
} from "../../services/inferenceApi";
import { ErrorHandler } from "../../errors/ErrorHandler";
import { base64ToFile } from "../../utils/fileUtils";

const MainContainer2: React.FC = () => {
	const [selectedDocs, setSelectedDocs] = useState<File[]>([]);
	const [processFile, setProcessFile] = useState<File | null>(null);
	const [issueSection, setIssueSection] = useState<string>("");
	const [issueReason, setIssueReason] = useState<string>("");
	const [highlightPattern, setHighlightPattern] = useState<string>("");
	const [dataError, setDataeError] = useState<string | null>(null);
	const [position, setPosition] = useState<Coordinates | undefined>(undefined);
	const [issueKey, setIssueKey] = useState<string>("");
	const [userAuth, setUserAuth] = useState<boolean>(true);
	const [entity, setEntity] = useState<string>(
		"FPT SOFTWARE  COMPANY LIMITED - DA NANG BRANCH",
	);

	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const token = queryParams.get("token");

		if (token) {
			fetchFileData(token);
		} else {
			console.log("Token not found in URL.");
		}
	}, [location]);

	const fetchFileData = async (token: string) => {
		try {
			const data = await fetchDocumentByToken(token);
			console.log(data);

			if (data) {
				let file = base64ToFile(data.fileData, data.fileName, data.fileType);
				setProcessFile(file);

				if (data.fileType === "docx") {
					file = await convertToPdf(file);
				}

				setSelectedDocs([file]);
				setIssueKey(data.issueKey);
				setUserAuth(data.userAuth);
				setEntity(data.entity);
				console.log("Selected file:", selectedDocs);
			}
		} catch (error) {
			console.error("Error in fetching file data:", error);
			const errorMessage = ErrorHandler.handleError(error);
			setDataeError(errorMessage);
		}
	};

	return (
		<div className="flex space-x-8">
			<Sidebar
				selectedDocs={selectedDocs}
				processFile={processFile}
				issueKey={issueKey}
				userAuth={userAuth}
				entity={entity}
				dataError={dataError}
				setUserAuth={setUserAuth}
				setEntity={setEntity}
				setIssueSection={setIssueSection}
				setIssueReason={setIssueReason}
				setHighlightPattern={setHighlightPattern}
				setPosition={setPosition}
			/>
			<DocumentContent
				selectedDocs={selectedDocs}
				issueSection={issueSection}
				issueReason={issueReason}
				highlightPattern={highlightPattern}
				position={position}
			/>
		</div>
	);
};

export default MainContainer2;
