import React, { useState } from "react";
import { useForm } from "../../hooks/useForm";
import { Coordinates } from "../../interfaces/ApiResponse";
import SelectInput from "../SelectInput";
import ContextTextarea from "../ContextTextArea";
import CheckTab from "../CheckTab";
import UploadFileTab from "../UploadFileTab";
import ResponsePanel from "../ResponsePanel";
import ArrowUp from "../../assets/ArrowUp.svg";
import ArrowDown from "../../assets/ArrowDown.svg";
import { entityOptions, ndaPurposeOptions } from "../../data/checklist";
import styles from "../../styles/Sidebar.module.css";

interface SidebarProps {
	selectedDocs: File[];
	processFile: File | null;
	issueKey: string;
	userAuth: boolean;
	entity: string;
	dataError: string | null;
	setUserAuth: React.Dispatch<React.SetStateAction<boolean>>;
	setEntity: React.Dispatch<React.SetStateAction<string>>;
	setIssueSection: React.Dispatch<React.SetStateAction<string>>;
	setIssueReason: React.Dispatch<React.SetStateAction<string>>;
	setHighlightPattern: React.Dispatch<React.SetStateAction<string>>;
	setPosition: React.Dispatch<React.SetStateAction<Coordinates | undefined>>;
}

const Sidebar: React.FC<SidebarProps> = ({
	selectedDocs,
	processFile,
	issueKey,
	userAuth,
	entity,
	dataError,
	setUserAuth,
	setEntity,
	setIssueSection,
	setIssueReason,
	setHighlightPattern,
	setPosition,
}) => {
	const [isContentVisible, setIsContentVisible] = useState(true);
	const [isLoadingContent, setIsLoadingContent] = useState(false);

	const {
		contextText,
		apiResponse,
		ndaPurpose,
		checkContentError,
		handleSubmit,
		handleNdaPurposeChange,
		handleEntityChange,
		handleUserAuthChange,
		handleTextChange,
	} = useForm(
		processFile,
		userAuth,
		entity,
		dataError,
		setUserAuth,
		setEntity,
		setIsLoadingContent,
	);

	const maxLength = 500;

	const toggleContentVisibility = () => {
		setIsContentVisible(!isContentVisible);
	};

	return (
		<div className="w-1/3 bg-white shadow p-6">
			<form onSubmit={handleSubmit}>
				<div className={styles.document}>
					<div className="flex justify-between items-center mb-4">
						<h2 className="text-black text-lg font-bold">Document</h2>
						<button type="button" onClick={toggleContentVisibility}>
							{isContentVisible ? (
								<img src={ArrowUp} alt="Collapse" />
							) : (
								<img src={ArrowDown} alt="Expand" />
							)}
						</button>
					</div>
					{isContentVisible && (
						<div>
							<SelectInput
								label="Entity"
								id="entity"
								value={entity}
								options={entityOptions}
								onChange={handleEntityChange}
							/>
							<SelectInput
								label="NDA Purpose"
								id="nda-purpose"
								value={ndaPurpose}
								options={ndaPurposeOptions}
								onChange={handleNdaPurposeChange}
							/>
							<SelectInput
								label="User Role"
								id="user_role"
								value={userAuth}
								options={[
									{ label: "LCM", value: true },
									{ label: "Non-LCM", value: false },
								]}
								onChange={handleUserAuthChange}
							/>
							<ContextTextarea
								contextText={contextText}
								maxLength={maxLength}
								handleTextChange={handleTextChange}
							/>
						</div>
					)}
					<CheckTab
						isLoadingContent={isLoadingContent}
						checkContentError={checkContentError}
					/>
				</div>
			</form>
			<UploadFileTab
				selectedDocs={selectedDocs}
				apiResponse={apiResponse}
				issueKey={issueKey}
			/>
			<ResponsePanel
				apiResponse={apiResponse}
				userAuth={userAuth}
				setIssueSection={setIssueSection}
				setIssueReason={setIssueReason}
				setHighlightPattern={setHighlightPattern}
				setPosition={setPosition}
			/>
		</div>
	);
};

export default Sidebar;
