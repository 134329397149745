export const entityOptions = [
	{
		label: "FPT SOFTWARE  COMPANY LIMITED - DA NANG BRANCH",
		value: "FPT SOFTWARE  COMPANY LIMITED - DA NANG BRANCH",
	},
	{
		label: "FPT SOFTWARE THANG LONG COMPANY LIMITED",
		value: "FPT SOFTWARE THANG LONG COMPANY LIMITED",
	},
	{
		label: "FPT SOFTWARE HOCHIMINH CO.,LTD",
		value: "FPT SOFTWARE HOCHIMINH CO.,LTD",
	},
	{
		label: "FPT SOFTWARE HOCHIMINH CO.,LTD-CAN THO BRANCH",
		value: "FPT SOFTWARE HOCHIMINH CO.,LTD-CAN THO BRANCH",
	},
	{ label: "FPT USA Corp.", value: "FPT USA Corp." },
	{ label: "FPT CANADA", value: "FPT CANADA" },
	{ label: "FPT SOFTWARE INDONESIA", value: "FPT SOFTWARE INDONESIA" },
	{ label: "FPT Slovakia s.r.o", value: "FPT Slovakia s.r.o" },
	{ label: "CzechFPT Czech s.r.o.", value: "CzechFPT Czech s.r.o." },
	{ label: "FPT Asia Pacific PTE. LTD", value: "FPT Asia Pacific PTE. LTD" },
	{ label: "FPT Australasia Pty. Ltd.", value: "FPT Australasia Pty. Ltd." },
	{
		label: "FPT Software (Thailand) Ltd.,",
		value: "FPT Software (Thailand) Ltd.,",
	},
	{
		label: "FPT Software Phillippines Corp.",
		value: "FPT Software Phillippines Corp.",
	},
	{
		label: "FPT Software solutions asia pacific pte. ltd.",
		value: "FPT Software solutions asia pacific pte. ltd.",
	},
	{
		label: "FPT Software Japan Company Limited",
		value: "FPT Software Japan Company Limited",
	},
	{
		label: "FPT Techno Japan Company Limited",
		value: "FPT Techno Japan Company Limited",
	},
	{ label: "Intellinet Consulting LLC.", value: "Intellinet Consulting LLC." },
	{ label: "FPT Taiwan Co., Ltd.", value: "FPT Taiwan Co., Ltd." },
	{
		label: "FPT Software Innovation Company Limited",
		value: "FPT Software Innovation Company Limited",
	},
	{
		label: "FPT Software Innovation Company Limited - Quy Nhon branch",
		value: "FPT Software Innovation Company Limited - Quy Nhon branch",
	},
	{
		label: "FPT Consulting Japan Co.,Ltd",
		value: "FPT Consulting Japan Co.,Ltd",
	},
	{
		label: "FPT NEARSHORE JAPAN CO., LTD.",
		value: "FPT NEARSHORE JAPAN CO., LTD.",
	},
	{ label: "FPT TECHNOLOGY DMCC", value: "FPT TECHNOLOGY DMCC" },
	{ label: "FPT Siam Limited", value: "FPT Siam Limited" },
	{
		label: "FPT Software Korea Co., ltd",
		value: "FPT Software Korea Co., ltd",
	},
	{
		label: "FPT SOFTWARE UNITED KINGDOM LTD.",
		value: "FPT SOFTWARE UNITED KINGDOM LTD.",
	},
	{
		label: "FPT Information System Singapore Pte. Ltd.",
		value: "FPT Information System Singapore Pte. Ltd.",
	},
	{
		label: "HOMA TECHNOLOGIES JOIN STOCK COMPANY",
		value: "HOMA TECHNOLOGIES JOIN STOCK COMPANY",
	},
	{
		label: "FPT Smart Technologies Company Limited",
		value: "FPT Smart Technologies Company Limited",
	},
	{ label: "MJS-FPT Company Limited", value: "MJS-FPT Company Limited" },
	{
		label: "FPT Software Malaysia Sdn. Bhd",
		value: "FPT Software Malaysia Sdn. Bhd",
	},
	{ label: "Aura Network Limited", value: "Aura Network Limited" },
	{
		label: "FPT SOFTWARE  COMPANY LIMITED",
		value: "FPT SOFTWARE  COMPANY LIMITED",
	},
	{
		label: "FPT SOFTWARE HANOI COMPANY LIMITED",
		value: "FPT SOFTWARE HANOI COMPANY LIMITED",
	},
	{ label: "AKAVAULT PTE. LTD.", value: "AKAVAULT PTE. LTD." },
	{
		label: "FPT SOFTWARE QUY NHON CO.LTD",
		value: "FPT SOFTWARE QUY NHON CO.LTD",
	},
	{
		label: "FPT Consulting service limited",
		value: "FPT Consulting service limited",
	},
	{
		label: "FPT Software Central Region Company Limited (FCR)",
		value: "FPT Software Central Region Company Limited (FCR)",
	},
	{ label: "FPT India Private Limited", value: "FPT India Private Limited" },
	{ label: "FPT Japan Holdings", value: "FPT Japan Holdings" },
	{
		label: "Shanghai FPT Software Co., Ltd.,",
		value: "Shanghai FPT Software Co., Ltd.,",
	},
	{ label: "FPT SOFTWARE EUROPE", value: "FPT SOFTWARE EUROPE" },
	{ label: "FPT Deutschland GmbH", value: "FPT Deutschland GmbH" },
	{
		label: "Suzhou FPT Software Co., Ltd",
		value: "Suzhou FPT Software Co., Ltd",
	},
	{
		label: "FPT Software Netherlands B.V.",
		value: "FPT Software Netherlands B.V.",
	},
	{
		label: "SBI FPT Limeted Lialibity company",
		value: "SBI FPT Limeted Lialibity company",
	},
	{
		label: "FPT Software Scandinavia ApS",
		value: "FPT Software Scandinavia ApS",
	},
	{
		label: "NAMI TECHNOLOGY JOINT STOCK COMPANY",
		value: "NAMI TECHNOLOGY JOINT STOCK COMPANY",
	},
	{ label: "FPT CONSULTING L.L.C", value: "FPT CONSULTING L.L.C" },
	{
		label: "NEXT ROBOTICS Joint-Stock Company",
		value: "NEXT ROBOTICS Joint-Stock Company",
	},
	{
		label: "FPT SOFTWARE HUE COMPANY LIMITED",
		value: "FPT SOFTWARE HUE COMPANY LIMITED",
	},
	{
		label: "FPT COMPANY FOR INFORMATION TECHNOLOGY",
		value: "FPT COMPANY FOR INFORMATION TECHNOLOGY",
	},
	{
		label: "FPT SOFTWARE RUMANIA S.R.L.",
		value: "FPT SOFTWARE RUMANIA S.R.L.",
	},
	{
		label: "Guangxi FPT Software Co., Ltd.",
		value: "Guangxi FPT Software Co., Ltd.",
	},
	{ label: "Intertec Consulting, LLC", value: "Intertec Consulting, LLC" },
	{
		label: "Intertec International, Ltd.",
		value: "Intertec International, Ltd.",
	},
	{
		label: "Micra Consulting International, Ltda",
		value: "Micra Consulting International, Ltda",
	},
	{
		label: "Intertec International S.A.S.",
		value: "Intertec International S.A.S.",
	},
	{
		label: "Intertec International S. de R.L. de C.V",
		value: "Intertec International S. de R.L. de C.V",
	},
	{ label: "FPT AUTOMOTIVE CORP", value: "FPT AUTOMOTIVE CORP" },
	{
		label: "FPT Solutions & Consulting SDN. BHD",
		value: "FPT Solutions & Consulting SDN. BHD",
	},
	{ label: "FPT NEW TECHNOLOGY COMPANY", value: "FPT NEW TECHNOLOGY COMPANY" },
	{ label: "FPT SOFTWARE EUROPE SARL", value: "FPT SOFTWARE EUROPE SARL" },
	{ label: "AOSIS Consulting SARL", value: "AOSIS Consulting SARL" },
	{ label: "FPT Software Sverige AB", value: "FPT Software Sverige AB" },
	{
		label: "BASE ENTERPRISE JOIN STOCK COMPANY",
		value: "BASE ENTERPRISE JOIN STOCK COMPANY",
	},
	{
		label: "Dalian FPT software company  Ltd.",
		value: "Dalian FPT software company  Ltd.",
	},
	{
		label: "P3 GROUP (VIET NAM) COMPANY LIMITED",
		value: "P3 GROUP (VIET NAM) COMPANY LIMITED",
	},
];

export const ndaPurposeOptions = [
	{
		label: "Confidentiality of specific project under MSA/SOW",
		value: "Confidentiality of specific project under MSA/SOW",
	},
	{
		label: "Get to know; or Get information for a quotation",
		value: "Get to know; or Get information for a quotation",
	},
];
