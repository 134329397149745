import React from "react";
import styles from "../styles/Sidebar.module.css";

interface ContextTextareaProps {
	contextText: string;
	maxLength: number;
	handleTextChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const ContextTextarea: React.FC<ContextTextareaProps> = ({
	contextText,
	maxLength,
	handleTextChange,
}) => {
	return (
		<div className="flex flex-col items-start">
			<h3 className="text-black mb-2">
				Context (Required) <span className="text-red-500">*</span>
			</h3>
			<textarea
				className={styles.contextTextarea}
				placeholder="Fill the context of NDA"
				value={contextText}
				onChange={handleTextChange}
				maxLength={maxLength}
				required
			/>
			<span className="text-gray-500 text-ms ml-auto">
				{contextText.length}/{maxLength}
			</span>
		</div>
	);
};

export default ContextTextarea;
