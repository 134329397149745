import React, { useState, useEffect } from "react";
import ResponseNotification from "./ResponseNotification";
import { ApiResponse, Coordinates } from "../interfaces/ApiResponse";
import { ReactComponent as FailIcon } from "../assets/FailIcon.svg";
import { ReactComponent as Icon } from "../assets/WarningIcon.svg";
import ArrowUp from "../assets/ArrowUpGray.svg";
import ArrowDown from "../assets/ArrowDownGray.svg";
import styles from "../styles/ResponsePanel.module.css";

interface ResponsePanelProps {
	apiResponse: ApiResponse | null;
	userAuth: boolean;
	setIssueSection: React.Dispatch<React.SetStateAction<string>>;
	setIssueReason: React.Dispatch<React.SetStateAction<string>>;
	setHighlightPattern: React.Dispatch<React.SetStateAction<string>>;
	setPosition: React.Dispatch<React.SetStateAction<Coordinates | undefined>>;
}

const ResponsePanel: React.FC<ResponsePanelProps> = ({
	apiResponse,
	userAuth,
	setIssueSection,
	setIssueReason,
	setHighlightPattern,
	setPosition,
}) => {
	const [isContentVisible, setIsContentVisible] = useState(false);

	const handleHighlight = (
		issueSection: string,
		issueReason: string,
		highlightPattern: string,
		coordinate: Coordinates,
	) => {
		setIssueSection(issueSection);
		setIssueReason(issueReason);
		setHighlightPattern(highlightPattern);
		setPosition(coordinate);
	};

	const toggleContentVisibility = () => {
		setIsContentVisible(!isContentVisible);
	};

	useEffect(() => {
		if (!apiResponse) {
			setIssueSection("");
			setIssueReason("");
		}
	}, [apiResponse, setIssueSection, setIssueReason]);

	if (apiResponse) {
		const isSuccess = !apiResponse.json || apiResponse.json.length === 0;

		return (
			<div className={styles.document}>
				<div className="flex justify-between items-center mb-4">
					<div className="flex items-center">
						<h3 className="font-bold text-xl mr-2">Checklist</h3>
						<button type="button" onClick={toggleContentVisibility}>
							{isContentVisible ? (
								<img src={ArrowUp} alt="Collapse" />
							) : (
								<img src={ArrowDown} alt="Expand" />
							)}
						</button>
					</div>
					<div className="flex items-center">
						<FailIcon />
						<p>{apiResponse.json.length} failed</p>
					</div>
				</div>
				{isContentVisible && (
					<div>
						<ResponseNotification isSuccess={isSuccess} userAuth={userAuth} />
						<ul className="checklist mb-6">
							{apiResponse.json.map((item, index) => (
								<li
									key={index}
									className="mb-4 border border-gray-200 px-3 py-3 rounded-lg hover:border-purple-600 transition-colors duration-200 cursor-pointer"
									onClick={() =>
										handleHighlight(
											item.errorSection,
											item.explanation,
											item.reference,
											item.coordinates,
										)
									}
								>
									<div className="flex justify-between items-center">
										<div className="flex items-center">
											<div style={{ flexBasis: "28px" }}>
												<Icon style={{ width: "28px", height: "28px" }} />
											</div>
											<h2 className="ml-2 font-semibold">
												{item.errorSection}
											</h2>
										</div>
										<button className={styles.autoFixButton}>
											{"Highlight Errors"}
										</button>
									</div>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		);
	}
	return null;
};

export default ResponsePanel;
