import React from "react";

const StateDisplay: React.FC = () => {
	return (
		<div className="flex justify-center m-2">
			<div className="w-6 h-6 rounded-full border-2 border-pink-500 flex items-center justify-center mr-2">
				<span className="text-pink-500">1</span>
			</div>
			<span>Check content</span>
			<span className="ml-2 mr-2 text-gray-300">
				--------------------------------------
			</span>
			<div className="w-6 h-6 bg-gray-200 rounded-full flex items-center justify-center mr-2">
				<span className="text-gray-700">2</span>
			</div>
			<span className="text-gray-700">Refine document</span>
		</div>
	);
};

export default StateDisplay;
