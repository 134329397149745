import React, { useState } from "react";
import Sidebar from "./Sidebar2";
import DocumentContent from "../DocumentContent";
import { Coordinates } from "../../interfaces/ApiResponse";

const MainContainer: React.FC = () => {
	const [selectedDocs, setSelectedDocs] = useState<File[]>([]);
	const [issueSection, setIssueSection] = useState<string>("");
	const [issueReason, setIssueReason] = useState<string>("");
	const [highlightPattern, setHighlightPattern] = useState<string>("");
	const [position, setPosition] = useState<Coordinates | undefined>(undefined);
	const [userAuth, setUserAuth] = useState<boolean>(true);
	const [entity, setEntity] = useState<string>(
		"FPT SOFTWARE  COMPANY LIMITED - DA NANG BRANCH",
	);

	return (
		<div className="flex space-x-8">
			<Sidebar
				selectedDocs={selectedDocs}
				issueKey={""}
				userAuth={userAuth}
				entity={entity}
				setUserAuth={setUserAuth}
				setEntity={setEntity}
				setSelectedDocs={setSelectedDocs}
				setIssueSection={setIssueSection}
				setIssueReason={setIssueReason}
				setHighlightPattern={setHighlightPattern}
				setPosition={setPosition}
			/>
			<DocumentContent
				selectedDocs={selectedDocs}
				issueSection={issueSection}
				issueReason={issueReason}
				highlightPattern={highlightPattern}
				position={position}
			/>
		</div>
	);
};

export default MainContainer;
