export const base64ToFile = (
	base64Data: string,
	fileName: string,
	mimeType: string,
): File => {
	// Convert base64 to blob
	const byteCharacters = atob(base64Data);
	const byteNumbers = new Array(byteCharacters.length);

	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}

	const byteArray = new Uint8Array(byteNumbers);
	const blob = new Blob([byteArray], { type: mimeType });

	// Create File object
	return new File([blob], fileName, { type: mimeType });
};
