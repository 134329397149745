import React, { useState, useRef } from "react";
import { ReactComponent as Icon } from "../../assets/DownloadIcon.svg";

interface DocumentInputProps {
	fileError: string | null;
	downloadUrl: string | null;
	fileName: string | undefined;
	setFileError: React.Dispatch<React.SetStateAction<string | null>>;
	handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DocumentInput: React.FC<DocumentInputProps> = ({
	fileError,
	downloadUrl,
	fileName,
	setFileError,
	handleFileChange,
}) => {
	const [hasFile, setHasFile] = useState(false);
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const isValidFileType = (file: File) => {
		const fileType = file.name.split(".").pop()?.toLowerCase();
		return fileType === "pdf" || fileType === "docx";
	};

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files && files.length > 0) {
			const file = files[0];
			if (isValidFileType(file)) {
				setHasFile(true);
				setFileError(null);
				handleFileChange(e);
			} else {
				setHasFile(false);
				setFileError("Please upload a .pdf or .docx file.");
				e.target.value = "";
			}
		} else {
			setHasFile(false);
			setFileError("Please select a file.");
		}
	};

	const handleDownload = () => {
		if (downloadUrl && fileName) {
			const link = document.createElement("a");
			link.href = downloadUrl;
			link.download = "edited_" + fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	return (
		<div>
			<h2 className="text-black mb-4">
				Supported file formats: PDF and .docx. (Required){" "}
				<span className="text-red-500">*</span>
			</h2>
			<h3 className="text-black mb-4">
				Please ensure your document is text-based (no handwritten, scanned, or
				image content), follows a clear structure, and excludes tables, charts,
				or images. Must be a standard NDA.
			</h3>
			<div className="flex flex-col bg-white rounded-lg shadow text-black font-semibold p-3 mb-4">
				<div className="flex items-center">
					<input
						ref={fileInputRef}
						className="w-full"
						type="file"
						accept=".pdf, .docx"
						required
						onChange={onFileChange}
					/>
					{(hasFile || downloadUrl) && (
						<button type="button" onClick={handleDownload}>
							<Icon />
						</button>
					)}
				</div>
				{fileError && <p className="text-red-500 mt-2">{fileError}</p>}
			</div>
		</div>
	);
};

export default DocumentInput;
