import React from "react";
import { ReactComponent as SuccessIcon } from "../assets/SuccessIcon.svg";
import { ReactComponent as FailIcon } from "../assets/XMark.svg";

interface ResponseNotificationProps {
	isSuccess: boolean;
	userAuth: boolean;
}

const NotificationWrapper: React.FC<{
	variant: "success" | "warning";
	icon: React.ReactNode;
	children: React.ReactNode;
}> = ({ variant, icon, children }) => {
	const variants = {
		success: {
			border: "border-green-400",
			bg: "bg-[#F6FFED]",
		},
		warning: {
			border: "border-yellow-400",
			bg: "bg-[#FFFBE6]",
		},
	};

	return (
		<div
			className={`flex items-center mb-4 border px-3 py-3 rounded-lg ${variants[variant].border} ${variants[variant].bg}`}
		>
			<div className="flex-none w-6">{icon}</div>
			<span className="ml-2">{children}</span>
		</div>
	);
};

const ResponseNotification: React.FC<ResponseNotificationProps> = ({
	isSuccess,
	userAuth,
}) => {
	const icon = (Icon: typeof SuccessIcon | typeof FailIcon) => (
		<Icon className="w-6 h-6" />
	);

	if (isSuccess) {
		return (
			<NotificationWrapper variant="success" icon={icon(SuccessIcon)}>
				The file meets all defined NDA's standards. Please continue to process
				the next step in FCMS
			</NotificationWrapper>
		);
	}

	return (
		<NotificationWrapper variant="warning" icon={icon(FailIcon)}>
			{!userAuth
				? "The contents of the NDA differ from the checklist"
				: "The contents of the NDA differ from the checklist. The Downloaded AI-fixed file needs to be reviewed by LCM and uploaded back to FCMS"}
		</NotificationWrapper>
	);
};

export default ResponseNotification;
