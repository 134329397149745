import { useState, useEffect } from "react";
import { AnonymizationService } from "../services/AnonymizationService";
import { ApiResponse } from "../interfaces/ApiResponse";
import { ErrorHandler } from "../errors/ErrorHandler";
import { inferenceApi, downloadFile } from "../services/inferenceApi";

export const useForm = (
	processFile: File | null,
	userAuth: boolean,
	entity: string,
	dataError: string | null,
	setUserAuth: React.Dispatch<React.SetStateAction<boolean>>,
	setEntity: React.Dispatch<React.SetStateAction<string>>,
	setIsLoadingContent: React.Dispatch<React.SetStateAction<boolean>>,
) => {
	const [contextText, setContextText] = useState("");
	const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
	const [ndaPurpose, setNdaPurpose] = useState<string>(
		"Confidentiality of specific project under MSA/SOW",
	);
	const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
	const [checkContentError, setCheckContentError] = useState<string | null>(
		null,
	);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setIsLoadingContent(true);
		setCheckContentError(null);

		try {
			console.log("Request sent");

			if (processFile) {
				const fileType = processFile.name.endsWith(".pdf") ? "pdf" : "docx";

				console.log(processFile);

				const response = await inferenceApi(
					entity,
					ndaPurpose,
					fileType,
					processFile,
				);

				console.log("Response:", response);
				if (response && response.api_output) {
					setApiResponse(response.api_output);

					if (userAuth) {
						const fileUrl = await downloadFile(response.download_link);
						setDownloadUrl(fileUrl);

						const link = document.createElement("a");
						link.href = fileUrl;
						link.download = "edited_" + processFile.name;
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					} else {
						setDownloadUrl(null);
					}

					console.log("Request sent successfully");
				} else {
					console.error("Invalid response from API");
					setApiResponse(null);
					setDownloadUrl(null);
				}
			}
		} catch (error) {
			console.error(">>> Error in API call:", error);
			setApiResponse(null);
			setDownloadUrl(null);
			const errorMessage = ErrorHandler.handleError(error);
			setCheckContentError(errorMessage);
		} finally {
			setIsLoadingContent(false);
		}
	};

	const handleEntityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setEntity(event.target.value);
	};

	const handleNdaPurposeChange = (
		event: React.ChangeEvent<HTMLSelectElement>,
	) => {
		setNdaPurpose(event.target.value);
	};

	const handleUserAuthChange = (
		event: React.ChangeEvent<HTMLSelectElement>,
	) => {
		setUserAuth(event.target.value === "true");
	};

	const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setContextText(e.target.value);
	};

	useEffect(() => {
		return () => {
			if (downloadUrl) {
				URL.revokeObjectURL(downloadUrl);
			}
		};
	}, [downloadUrl]);

	useEffect(() => {
		setCheckContentError(dataError);
	}, [dataError]);

	return {
		contextText,
		apiResponse,
		ndaPurpose,
		checkContentError,
		handleSubmit,
		handleNdaPurposeChange,
		handleEntityChange,
		handleUserAuthChange,
		handleTextChange,
	};
};
